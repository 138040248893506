import React from "react";
import * as styles from "./Landing.module.css";
import { AppMarketplaceSection } from "./components/AppMarketplaceSection";
import { DocumentationSection } from "./components/DocumentationSection";
import { DeveloperCenterSection } from "./components/DeveloperCenterSection";
import screenTear from "../../assets/screenTear/screentear.svg";

export function Landing() {
  return (
    <div className={styles.landingPage}>
      <div className={styles.developerCenterSection}>
        <DeveloperCenterSection />
      </div>
      <div
        style={{ backgroundImage: `url(${screenTear})` }}
        className={styles.documentationSection}
      >
        <DocumentationSection />
      </div>
      <div className={styles.appMarketplaceSection}>
        <AppMarketplaceSection />
      </div>
    </div>
  );
}
