import React from "react";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { GoogleFontLabel } from "@/utils/sharedComponents";
import * as styles from "./DocumentationSection.module.css";

export function DocumentationSection() {
  return (
    <>
      <div className={styles.documentationSectionContainer}>
        <div className={styles.title}>
          <GoogleFontLabel label="DOCUMENTATION" variation={"light"} />
        </div>

        <div className={styles.header}>
          <Heading level={1}>
            Start <Emphasis variation="highlight">building</Emphasis> with
            Jobber.
          </Heading>
        </div>

        <div className={styles.subheading}>
          Leverage our developer ecosystem to kickstart your creation -
          we&apos;ll be there to support you from idea to launch.
        </div>

        <div className={styles.cardSection}>
          <Card url="/docs/#about-jobber">
            <Content>
              <Heading level={2}>GraphQL API</Heading>
              <Text>
                Our API offers a robust set of queries, mutations, and webhooks,
                as well as developer tools such as GraphiQL, API Versioning, and
                template projects.
              </Text>
            </Content>
          </Card>
          <Card url="https://atlantis.getjobber.com/" external={true}>
            <Content>
              <Heading level={2}>Design System</Heading>
              <Text>
                Accelerate your development with Atlantis (Jobber&apos;s open
                source design system) and create a front-end that feels like
                Jobber with less effort.
              </Text>
            </Content>
          </Card>
          <Card url="/docs/getting_started/">
            <Content>
              <Heading level={2}>Getting Started</Heading>
              <Text>
                Follow our step-by-step guides to create your first app, make
                API requests, and start building using a template project.
              </Text>
            </Content>
          </Card>
        </div>
      </div>
    </>
  );
}
