import React from "react";
import { Text } from "@jobber/components/Text";
import { Link } from "@/components/Link";

export function ForgotPasswordPrompt() {
  return (
    <Text>
      <Link to="/forgot_password">Forgot Password?</Link>
    </Text>
  );
}
