import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { GoogleFontLabel } from "@/utils/sharedComponents";
import * as styles from "./DeveloperCenterSection.module.css";
import { LoginSignUpCTAs } from "./LoginSignUpCTAs";
import graphiqlImage from "../../../../assets/developerGraphiqlExample/developer-graphiql-example.svg";

export function DeveloperCenterSection() {
  return (
    <div className={styles.developerCenterSectionContainer}>
      <div className={styles.signupSectionContainer}>
        <GoogleFontLabel label="JOBBER DEVELOPER CENTER" />

        <div className={styles.heading}>
          <Heading level={1}>
            When small businesses succeed, we all{" "}
            <Emphasis variation="highlight">succeed</Emphasis>.
          </Heading>
        </div>
        <div className={styles.description}>
          With access to over 200,000 Home Service Pros around the globe,
          Jobber&apos;s Developer Center provides a modern technology stack for
          you to imagine the future of service business management - all while
          helping the people of small businesses be successful.
        </div>

        <div className={styles.desktopInteractiveSection}>
          <LoginSignUpCTAs />
        </div>
      </div>

      <div className={styles.imagesSectionContainer}>
        <img src={graphiqlImage} className={styles.graphiqlImage} />
      </div>

      <div className={styles.mobileInteractiveSection}>
        <LoginSignUpCTAs />
      </div>
    </div>
  );
}
