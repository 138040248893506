import React from "react";
import { useTermsOfServiceQuery } from "@/hooks/useTermsOfServiceQuery";
import { TermsOfServicePrompt } from "./TermsOfServicePrompt";

interface TermsOfServicePromptProps {
  acceptedTermsOfService?: boolean;
  onAcceptedTermsOfService?(input: boolean): void;
}

export function TermsOfServicePromptLoader({
  acceptedTermsOfService,
  onAcceptedTermsOfService,
}: TermsOfServicePromptProps) {
  const {
    tos: { markdown: content },
  } = useTermsOfServiceQuery();

  return (
    <TermsOfServicePrompt
      content={content}
      acceptedTermsOfService={acceptedTermsOfService}
      onAcceptedTermsOfService={onAcceptedTermsOfService}
    />
  );
}
