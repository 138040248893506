import { graphql, useStaticQuery } from "gatsby";

export const useTermsOfServiceQuery = () => {
  const data = useStaticQuery(
    graphql`
      query TermsOfServiceQuery {
        tos: markdownRemark(
          frontmatter: { title: { eq: "Terms of Service" } }
        ) {
          markdown: rawMarkdownBody
        }
      }
    `,
  );
  return data;
};
