import React from "react";
import { Text } from "@jobber/components/Text";
import { Link } from "@/components/Link";

export function SignupPrompt() {
  return (
    <div>
      <Text>
        Want to build a partner app? <Link to="/signup">Sign up</Link>
      </Text>
    </div>
  );
}
