import React, { useEffect, useState } from "react";
import { Markdown } from "@jobber/components/Markdown";
import { Text } from "@jobber/components/Text";
import { Modal } from "@jobber/components/Modal";
import { Checkbox } from "@jobber/components/Checkbox";
import * as styles from "@/components/Link/Link.module.css";

interface TermsOfServicePromptProps {
  content: string;
  acceptedTermsOfService?: boolean;
  onAcceptedTermsOfService?(input: boolean): void;
  onModalOpen?(): void;
}
export function TermsOfServicePrompt({
  content,
  acceptedTermsOfService,
  onAcceptedTermsOfService,
  onModalOpen,
}: TermsOfServicePromptProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return (
    <>
      <Checkbox
        onChange={onAcceptedTermsOfService}
        checked={acceptedTermsOfService}
      >
        <Text>
          I have read and agree to the Developer Center{" "}
          <a
            style={{ cursor: "pointer" }}
            className={styles.link}
            onClick={handleTermsOfServiceOnClick}
          >
            Terms of Service
          </a>
        </Text>
      </Checkbox>
      {isBrowser && (
        <Modal
          title="Terms of Service"
          open={isModalOpen}
          onRequestClose={handleModalRequestClose}
        >
          <Markdown content={content} />
        </Modal>
      )}
    </>
  );

  function handleTermsOfServiceOnClick(
    event: React.MouseEvent<HTMLAnchorElement>,
  ) {
    event.preventDefault();
    onModalOpen && onModalOpen();
    setIsModalOpen(true);
  }

  function handleModalRequestClose() {
    setIsModalOpen(false);
  }
}
