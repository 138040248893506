import React from "react";
import { Button } from "@jobber/components/Button";
import { LoginPrompt } from "@/features/Authentication/components";
import * as styles from "./LoginSignUpCTAs.module.css";

export function LoginSignUpCTAs() {
  return (
    <>
      <div className={styles.buttonSection}>
        <div className={styles.signupButton}>
          <Button url="/signup" size="base" label={"Sign Up"} />
        </div>
        <div className={styles.aboutJobberButton}>
          <Button
            css={{}}
            external={true}
            url="https://getjobber.com/why-jobber/"
            type={"secondary"}
            size="base"
            label={"About Jobber"}
          />
        </div>
      </div>
      <div className={styles.loginText}>
        <LoginPrompt />
      </div>
    </>
  );
}
