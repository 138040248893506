import React from "react";
import { Page } from "@/components/Page";
import { withoutAuthentication } from "@/features/Authentication/AccessRestriction";
import { Landing } from "@/views/Landing";

const LandingPage = () => {
  return (
    <Page variation="presession" fullWidth>
      <Landing />
    </Page>
  );
};

export default withoutAuthentication(LandingPage);
