import React from "react";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { GoogleFontLabel } from "@/utils/sharedComponents";
import * as styles from "./AppMarketplaceSection.module.css";
import appLogosSquare from "../../../../assets/appLogoSquare/AppImage-Dark.svg";

export function AppMarketplaceSection() {
  return (
    <>
      <div className={styles.appMarketplaceSectionContainer}>
        <div className={styles.learnMoreSectionContainer}>
          <GoogleFontLabel label="APP MARKETPLACE" />
          <div className={styles.heading}>
            <Heading level={1}>
              You&apos;ll be in <Emphasis variation="highlight">great</Emphasis>{" "}
              company.
            </Heading>
          </div>
          <div className={styles.description}>
            Join the likes of industry-leading apps like QuickBooks Online,
            CompanyCam, Thumbtack, and many others and get the eyes of over
            200,000 Home Service Pros.
          </div>
          <div className={styles.learnMoreButton}>
            <Button
              label="Learn More"
              external={true}
              url="https://getjobber.com/integrations/"
            />
          </div>
        </div>
        <div className={styles.appLogosSquareContainer}>
          <img src={appLogosSquare} className={styles.appLogosSquare} />
        </div>
      </div>
    </>
  );
}
